import $ from 'jquery';
import 'slick-carousel';
import './firebase';

$('#burger').on('click', () => {
    $('#nav').toggleClass('show');
});

$(".slider").not('.slick-initialized').slick();

$('.carousel').slick({
    arrows: false,
    dots: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'ease',
});